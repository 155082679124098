import axios from 'axios';
import { config } from '../config';
import { getAccessToken, setTokens, removeTokens} from '../utils/auth';

const api = axios.create({
  baseURL: config.apiBaseUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken != null){
          const response = await axios.post(`${config.apiBaseUrl}/account/refresh/`, { refresh_token: refreshToken });
          setTokens(response.data.access_token, response.data.refresh_token);
          return api(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh token error (e.g., logout user)
        removeTokens();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const registerUser = async (token: string, password: string) => {
  const response = await api.post('/account/register/', { token, password });
  return response.data;
};

export const loginUser = async (email: string, password: string, token?: string) => {
  const payload = token ? { 'email':email, 'password':password, 'token':token } : { 'email': email, 'password':password };
  const response = await api.post('/account/login/', payload);
  return response.data;
};

export const registerAndJoin = async (email: string, joinCode: string) => {
  const response = await api.post('/account/register/join/', { email, join_code: joinCode });
  return response.data;
};

export const joinBarCrawl = async (joinCode: string) => {
  const response = await api.post('/play/join/', { join_code: joinCode });
  return response.data;
};

export const getBarCrawlList = async () => {
  const response = await api.get('/play/list/');
  return response.data;
};

export const getBarCrawlState = async (groupId: string) => {
  const response = await api.get(`/play/?groupid=${groupId}`);
  return response.data;
};

export const startBarCrawl = async (groupId: string) => {
  const response = await api.post('/play/start/', { groupid: groupId });
  return response.data;
};

export const submitAnswer = async (groupId: string, stepNum: number, questionNum: number, answer: string) => {
  const response = await api.post('/play/submit/', { groupid: groupId, step_num: stepNum, question_num: questionNum, answer });
  return response.data;
};

export const submitDrinks = async (groupId: string, stepNum: number, drinks: Record<number, number>) => {
  const response = await api.post('/play/submit/', { groupid: groupId, step_num: stepNum, question_num: -1, answer: drinks });
  return response.data;
};

export const nextStep = async (groupId: string, stepNum: number) => {
  const response = await api.post('/play/next/', { groupid: groupId, step_num: stepNum });
  return response.data;
};

export const getUserInfo = async () => {
  const response = await api.get('/account/');
  return response.data;
};

export const updateUsername = async (username: string) => {
  const response = await api.post('/account/username/', { username });
  return response.data;
};

export const logoutUser = async () => {
  const response = await api.post('/account/logout/');
  removeTokens();
  return response.data;
};

interface StateUpdateResponse {
  payload: {
    success: boolean;
    step_num: number;
    member_count: number;
    group_name: string;
    created: string;
    started: string | null;
    ended: string | null;
    questions_submitted: number[];
    score: number | null;
    members: string[]; // Added this line
  };
  details: Record<string, any>;
}

export const fetchStateUpdate = async (groupId: string): Promise<StateUpdateResponse> => {
  const response = await api.get(`/play/update/?groupid=${groupId}`);
  return response.data;
};

// New function for development testing
export const createDevBarCrawl = async () => {
  const response = await api.post('/play/dev/create/');
  return response.data;
};

// New function to change group name
export const changeGroupName = async (groupId: string, newName: string) => {
  const response = await api.post('/play/group_name/', { groupid: groupId, new_name: newName });
  return response.data;
};

// New function to invite user
export const inviteUser = async (groupId: string, email: string) => {
  const response = await api.post('/play/invite/', { groupid: groupId, email: email });
  return response.data;
};

// New function to change password
export const changePassword = async (oldPassword: string, newPassword: string) => {
  const response = await api.post('/account/password/', { old_password: oldPassword, new_password: newPassword });
  return response.data;
};

export default api;
