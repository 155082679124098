import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, List, ListItem, ListItemText, ListItemIcon, Fab, Chip, Button, TextField, Box } from '@mui/material';
import { Add as AddIcon, LocalBar as LocalBarIcon, LocationOn as LocationIcon } from '@mui/icons-material';
import { getBarCrawlList, createDevBarCrawl, joinBarCrawl } from '../services/api';
import { config } from '../config';

interface BarCrawl {
  groupid: string;
  group_name: string | null;
  created: string;
  started: string | null;
  ended: string | null;
  step_num: number;
  score: number | null;
  event_name: string;
  city: string | null;
  state: string | null;
}

const BarCrawlListPage: React.FC = () => {
  const [barCrawls, setBarCrawls] = useState<BarCrawl[]>([]);
  const [joinCode, setJoinCode] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const fetchedRef = useRef(false);

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchBarCrawls();
      fetchedRef.current = true;
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isNew = params.get('new') === 'true' || params.get('new') === 'True';
    
    if (isNew && barCrawls.length > 0) {
      // Find first not complete barcrawl
      const firstNotComplete = barCrawls.find(bc => !bc.ended);
      if (firstNotComplete) {
        navigate(`/barcrawl/play/${firstNotComplete.groupid}`);
      }
    }
  }, [barCrawls, location.search, navigate]);

  const fetchBarCrawls = async () => {
    try {
      const response = await getBarCrawlList();
      setBarCrawls(response.payload.barcrawls);
    } catch (error) {
      console.error('Failed to fetch bar crawls:', error);
      // TODO: Show error message to user
    }
  };


  const handleJoinBarCrawl = async () => {
    try {
      const response = await joinBarCrawl(joinCode);
      if (response.payload.success) {
        fetchBarCrawls();
        setJoinCode('');
      } else {
        // TODO: Show error message to user
        console.error('Failed to join BarCrawl:', response.message);
      }
    } catch (error) {
      navigate('/login');
      console.error('Failed to join BarCrawl:', error);
      // TODO: Show error message to user
    }
  };

  const getStatus = (barCrawl: BarCrawl) => {
    if (barCrawl.ended) return 'complete';
    if (barCrawl.started) return 'active';
    return 'ready';
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return '#4CAF50'; // Green
      case 'ready':
        return '#FF8C00'; // Dark Orange
      case 'complete':
        return '#F44336'; // Red
      default:
        return '#757575'; // Grey (default)
    }
  };

  const sortedBarCrawls = [...barCrawls].sort((a, b) => {
    const statusA = getStatus(a);
    const statusB = getStatus(b);
    if (statusA === statusB) {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    }
    return statusA === 'active' ? -1 : statusB === 'active' ? 1 : 0;
  });

  return (
    <Container>
      <Box
        component="img"
        sx={{
          width: '100%',
          height: 'auto',
          marginBottom: 2,
        }}
        alt="Bar Crawl Banner"
        src={config.bannerImageUrl}
      />
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', fontFamily: config.fonts.heading }}>
        Join a Group
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <TextField
          value={joinCode}
          onChange={(e) => setJoinCode(e.target.value)}
          label="Enter 6-digit code"
          variant="outlined"
          inputProps={{ maxLength: 6 }}
          sx={{ mr: 1 }}
        />
        <Button variant="contained" color="primary" onClick={handleJoinBarCrawl}>
          Join
        </Button>
      </Box>

      <List>
        {sortedBarCrawls.map((barCrawl) => (
          <ListItem
            key={barCrawl.groupid}
            button
            onClick={() => navigate(`/barcrawl/play/${barCrawl.groupid}`)}
          >
            <ListItemIcon>
              <LocalBarIcon style={{ color: getStatusColor(getStatus(barCrawl)) }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontFamily: config.fonts.listTitle }}>
                  {barCrawl.group_name || barCrawl.event_name}
                </Typography>
              }
              secondary={
                <Typography style={{ fontFamily: config.fonts.listDetails }}>
                  <LocationIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                  {`${barCrawl.city || ''} ${barCrawl.state || ''}`}
                </Typography>
              }
            />
            <Chip
              label={getStatus(barCrawl)}
              style={{ backgroundColor: getStatusColor(getStatus(barCrawl)), color: 'white' }}
              size="small"
            />
          </ListItem>
        ))}
      </List>
      
    </Container>
  );
};

export default BarCrawlListPage;
