import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Container, Typography, Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { LocalBar as LocalBarIcon, LocationOn as LocationIcon, Person as PersonIcon,  Add as AddIcon, Remove as RemoveIcon  } from '@mui/icons-material';
import { getBarCrawlState, startBarCrawl, submitAnswer, submitDrinks, nextStep, fetchStateUpdate, changeGroupName, inviteUser } from '../services/api';
import ResultsDisplay from '../components/ResultsDisplay';
import { config } from '../config';

interface BarCrawlState {
  groupid: string;
  invites: number;
  group_name: string;
  created: string;
  started: string | null;
  ended: string | null;
  step_num: number;
  questions_submitted: number[];
  score: number | null;
  bucket_url: string;
  cover_image: string;
  event_name: string;
  event_type: string;
  city: string;
  state: string;
  members: string[];
  is_admin: boolean;
  score_json: any;
  join_code: string;
}

interface BarCrawlJson {
  version: number;
  event_name: string;
  event_city: string;
  event_state: string;
  total_steps: number;
  cover_image: string;
  event: Array<{
    title: string;
    subtitle?: string;
    location: string;
    image?: string;
    questions: Array<{
      title: string;
      choices: Record<string, string>;
    }>;
  }>;
}

interface StateUpdatePayload {
  success: boolean;
  step_num: number;
  member_count: number;
  group_name: string;
  created: string;
  started: string | null;
  ended: string | null;
  questions_submitted: number[];
  score: number | null;
  score_json?: any;
  members?: string[];
}

const ANSWER_BORDER_COLOR = '#39c1e6';

const PlayPage: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const [barCrawlState, setBarCrawlState] = useState<BarCrawlState | null>(null);
  const [barCrawlJson, setBarCrawlJson] = useState<BarCrawlJson | null>(null);
  const [inviteEmail, setInviteEmail] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [drinkCounts, setDrinkCounts] = useState<Record<number, number>>({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 });
  const [openDrinkDialog, setOpenDrinkDialog] = useState(false);
  const [openGroupNameDialog, setOpenGroupNameDialog] = useState(false);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [localStepNum, setLocalStepNum] = useState<number>(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState<number[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isGameEnded, setIsGameEnded] = useState(false);

  const updateIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopyJoinCode = () => {
    if (barCrawlState) {
      const joinLink = `${config.appBaseUrl}/?join_code=${barCrawlState.join_code}`;
      navigator.clipboard.writeText(joinLink).then(() => {
        setOpenSnackbar(true);
      });
    }
  };
  
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const fetchBarCrawlState = useCallback(async () => {
    if (!groupId) return;
    try {
      const response = await getBarCrawlState(groupId);
      setBarCrawlState(response.payload);
      setAnsweredQuestions(response.payload.questions_submitted);
      setLocalStepNum(response.payload.step_num);
      setIsGameEnded(!!response.payload.ended);
      if (response.payload.bucket_url) {
        const jsonResponse = await fetch(response.payload.bucket_url);
        const json = await jsonResponse.json();
        setBarCrawlJson(json);
      }
    } catch (error) {
      console.error('Failed to fetch bar crawl state:', error);
      // TODO: Show error message to user
    }
  }, [groupId]);

  const applyStateUpdate = (payload: StateUpdatePayload) => {
    setAnsweredQuestions(payload.questions_submitted);
    
    setBarCrawlState(prevState => {
      if (!prevState) return null;
      return {
        ...prevState,
        group_name: payload.group_name,
        started: payload.started,
        ended: payload.ended,
        questions_submitted: payload.questions_submitted,
        step_num: payload.step_num,
        score: payload.score,
        score_json: payload.score_json,
        members: payload.members || prevState.members,
      };
    });
      
    if (barCrawlJson && barCrawlState) {
      if (payload.step_num < barCrawlJson.event.length) {
        const currentStepQuestions = barCrawlJson.event[payload.step_num].questions.length;
        const allQuestionsAnswered = payload.questions_submitted.length === currentStepQuestions;
        setShowNextButton(allQuestionsAnswered);
      } else {
        setShowNextButton(false);
      }
    }
    
    setIsGameEnded(!!payload.ended);
  };

  const updateBarCrawlState = useCallback(async () => {
    if (!groupId) return;
    try {
      const data = await fetchStateUpdate(groupId);
      const payload = data.payload as StateUpdatePayload;

      if (payload.success) {
        applyStateUpdate(payload);
      }
    } catch (error) {
      console.error('Failed to fetch state update:', error);
    }
  }, [groupId, barCrawlJson]);

  useEffect(() => {
    fetchBarCrawlState();
  }, [fetchBarCrawlState]);

  useEffect(() => {
    const startUpdateInterval = () => {
      if (barCrawlState && !isGameEnded) {
        updateIntervalRef.current = setInterval(updateBarCrawlState, 5000);
      }
    };

    const stopUpdateInterval = () => {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
        updateIntervalRef.current = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopUpdateInterval();
      } else {
        startUpdateInterval();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    startUpdateInterval();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      stopUpdateInterval();
    };
  }, [updateBarCrawlState, barCrawlState, isGameEnded]);

  useEffect(() => {
    if (isGameEnded) {
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
        updateIntervalRef.current = null;
      }
    }
  }, [isGameEnded]);

  const handleStartBarCrawl = async () => {
    if (!groupId) return;
    try {
      const data = await startBarCrawl(groupId);
      const payload = data.payload as StateUpdatePayload;
      applyStateUpdate(payload);
    } catch (error) {
      console.error('Failed to start bar crawl:', error);
      // TODO: Show error message to user
    }
  };

  const handleSubmitAnswer = async (answer: string, questionIndex: number) => {
    if (!groupId || !barCrawlState) return;
    try {
      const data = await submitAnswer(groupId, localStepNum, questionIndex, answer);
      const payload = data.payload as StateUpdatePayload;
      applyStateUpdate(payload);
    } catch (error) {
      console.error('Failed to submit answer:', error);
      // TODO: Show error message to user
    }
  };

  const handleNextStep = async () => {
    if (!groupId || !barCrawlState) return;
    try {
      console.log("next step");
      const data = await nextStep(groupId, localStepNum);
      const payload = data.payload as StateUpdatePayload;
      console.log("Applying update", payload);
      applyStateUpdate(payload);
      setLocalStepNum(payload.step_num);
      setAnsweredQuestions([]);
      setDrinkCounts({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 })
      console.log("New State", barCrawlState);
    } catch (error) {
      console.error('Failed to move to next step:', error);
      // TODO: Show error message to user
    }
  };

  const handleOpenDrinkDialog = () => {
    setOpenDrinkDialog(true);
  };

  const handleCloseDrinkDialog = () => {
    setOpenDrinkDialog(false);
  };

  const handleSubmitDrinks = async () => {
    if (!groupId || !barCrawlState) return;
    try {
      await submitDrinks(groupId, localStepNum, drinkCounts);
      handleCloseDrinkDialog();
    } catch (error) {
      console.error('Failed to submit drinks:', error);
      // TODO: Show error message to user
    }
  };

  const handleOpenGroupNameDialog = () => {
    setOpenGroupNameDialog(true);
  };

  const handleCloseGroupNameDialog = () => {
    setOpenGroupNameDialog(false);
  };

  const handleSubmitGroupName = async () => {
    if (!groupId || !barCrawlState) return;
    try {
      const response = await changeGroupName(groupId, newGroupName);
      if (response.payload && response.payload.success) {
        setBarCrawlState({ ...barCrawlState, group_name: newGroupName });
        handleCloseGroupNameDialog();
      } else {
        // TODO: Show error message to user
        console.error('Failed to change group name:', response);
      }
    } catch (error) {
      console.error('Failed to change group name:', error);
      // TODO: Show error message to user
    }
  };

  const handleOpenInviteDialog = () => {
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  const handleSubmitInvite = async () => {
    if (!groupId || !barCrawlState) return;
    try {
      const response = await inviteUser(groupId, inviteEmail);
      if (response.payload && response.payload.success) {
        // TODO: Show success message to user
        setInviteEmail('');
        handleCloseInviteDialog();
      } else {
        // TODO: Show error message to user
        console.error('Failed to invite user:', response);
      }
    } catch (error) {
      console.error('Failed to invite user:', error);
      // TODO: Show error message to user
    }
  };

  const generateUserColor = (username: string) => {
    let hash = 0;
    for (let i = 0; i < username.length; i++) {
      hash = username.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 60%)`;
    return color;
  };

  if (!barCrawlState || !barCrawlJson) {
    return <Typography>Loading...</Typography>;
  }

  const currentStep = barCrawlJson.event[localStepNum];
  const isBarCrawlCompleted = localStepNum >= barCrawlJson.total_steps;
  const allQuestionsAnswered = currentStep && answeredQuestions.length === currentStep.questions.length;

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        {!barCrawlState.started ? (
          <div>
            <Typography variant="h4" align="center" gutterBottom>
              {barCrawlJson.event_name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
              <LocationIcon style={{ marginRight: '0.5rem' }} />
              Start at {barCrawlJson.event[0].title} <br></br> {barCrawlJson.event[0].location}
            </Typography>

            {barCrawlState.is_admin ? (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <QRCodeSVG 
                  value={`${config.appBaseUrl}/?join_code=${barCrawlState.join_code}`}
                  size={256}
                  level="H"
                  includeMargin={true}
                />
              </div>
            ) : barCrawlState.cover_image && (
              <img src={barCrawlState.cover_image} alt="Event Cover" style={{ width: '100%', marginBottom: '1rem' }} />
            )}
            <Typography variant="h4" align="center" gutterBottom>
              {barCrawlState.group_name || "Your Group"}
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              Invites remaining: {barCrawlState.invites - barCrawlState.members.length}
            </Typography>
            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: '1rem', fontFamily: 'Courier New, monospace', letterSpacing: '0.2em' }}>
              Join Code: {barCrawlState.join_code}
              <IconButton onClick={handleCopyJoinCode} size="small" style={{ marginLeft: '0.1rem' }}>
                <ContentCopyIcon /> Copy
              </IconButton>
            </Typography>
            <Snackbar
              anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
              }}
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              message="Join link copied to clipboard!"
            />
            
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <Button variant="contained" color="primary" onClick={handleOpenGroupNameDialog} style={{ marginRight: '1rem' }}>
                Change Group Name
              </Button>
              {barCrawlState.is_admin && (
                <Button variant="contained" color="primary" onClick={handleOpenInviteDialog}>
                  Invite
                </Button>
              )}
            </div>
            {barCrawlState.is_admin && (
              <Button variant="contained" color="primary" onClick={handleStartBarCrawl} fullWidth style={{ marginBottom: '1rem' }}>
                Start Bar Crawl
              </Button>
            )}
            <List>
              {barCrawlState.members.map((member, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <PersonIcon style={{ color: generateUserColor(member) }} />
                  </ListItemIcon>
                  <ListItemText primary={member} />
                </ListItem>
              ))}
            </List>
          </div>
        ) : isBarCrawlCompleted ? (
          <div>
            <Typography variant="h5" gutterBottom>
              Bar Crawl Completed!
            </Typography>
            {barCrawlState.score_json ? (
              <ResultsDisplay scoreJson={barCrawlState.score_json} barcrawlJson={barCrawlJson} />
            ) : (
              <Typography>Loading results...</Typography>
            )}
          </div>
        ) : currentStep ? (
          <div>
            <Typography variant="h5" gutterBottom>
              {currentStep.title}
            </Typography>
            {currentStep.subtitle && (
              <Typography variant="subtitle1" gutterBottom>
                {currentStep.subtitle}
              </Typography>
            )}
            <Typography variant="body1" gutterBottom>
              <LocationIcon fontSize="small" style={{ verticalAlign: 'middle', marginRight: '5px' }} /> {currentStep.location}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Current Step: {localStepNum + 1} / {barCrawlJson.total_steps}
            </Typography>
            {currentStep.image && (
              <img src={currentStep.image} alt={currentStep.title} style={{ maxWidth: '100%', marginBottom: '1rem' }} />
            )}
            {(localStepNum == barCrawlState.step_num) && currentStep.questions.map((question, index) => (
              !answeredQuestions.includes(index) && (
                <div key={index} style={{ marginBottom: '2rem' }}>
                  <Typography variant="h6" gutterBottom>
                    Question {index + 1}: {question.title}
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {Object.entries(question.choices).map(([key, value]) => (
                      <Button
                        key={key}
                        variant="outlined"
                        onClick={() => handleSubmitAnswer(key, index)}
                        style={{
                          border: `2px solid ${ANSWER_BORDER_COLOR}`,
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          backgroundColor: 'transparent',
                          color: '#000',
                          justifyContent: 'flex-start',
                          padding: '1rem',
                          textTransform: 'none',
                        }}
                      >
                        {value}
                      </Button>
                    ))}
                  </div>
                </div>
              )
            ))}
            {(showNextButton||localStepNum != barCrawlState.step_num) && (<>
            <br></br>
            <Typography variant="h6" gutterBottom>
                    Don't forget to track your drinks!
                  </Typography>
              <Button variant="contained" color="primary" onClick={handleNextStep} style={{ marginTop: '1rem' }}>
                Next Step
              </Button>
              </>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenDrinkDialog}
              startIcon={<LocalBarIcon />}
              style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}
            >
              Track Drinks
            </Button>
          </div>
        ) : (
          <Typography>No more steps available.</Typography>
        )}
      </Paper>
      <Dialog open={openDrinkDialog} onClose={handleCloseDrinkDialog}>
        <DialogTitle>Drinks at {currentStep ? (currentStep.title):("") }</DialogTitle>
        <DialogContent>
          {Object.entries(drinkCounts).map(([key, value]) => (
            <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <img 
                src={[config.icons.beer, config.icons.seltzer, config.icons.mixed_drink, config.icons.wine, config.icons.shot][parseInt(key)]} 
                alt={['Beer', 'Seltzer', 'Mixed Drink', 'Wine', 'Shot'][parseInt(key)]}
                style={{ height: '100px', marginRight: '1rem', cursor: 'pointer' }}
                onClick={() => setDrinkCounts(prev => ({ ...prev, [key]: (prev[parseInt(key)] || 0) + 1 }))}
              />
              <IconButton onClick={() => setDrinkCounts(prev => ({ ...prev, [key]: Math.max((prev[parseInt(key)] || 0) - 1, 0) }))}>
                <RemoveIcon />
              </IconButton>
              <TextField
                label={['Beers', 'Seltzers', 'Mixed Drinks', 'Wines', 'Shots'][parseInt(key)]}
                type="number"
                value={value}
                onChange={(e) => setDrinkCounts({ ...drinkCounts, [key]: parseInt(e.target.value) || 0 })}
                style={{ width: '100px', margin: '0 0.5rem' }}
              />
              <IconButton onClick={() => setDrinkCounts(prev => ({ ...prev, [key]: (prev[parseInt(key)] || 0) + 1 }))}>
                <AddIcon />
              </IconButton>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDrinkDialog} color="primary" variant="outlined" >
            Close
          </Button>
          <Button onClick={handleSubmitDrinks} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openGroupNameDialog} onClose={handleCloseGroupNameDialog}>
        <DialogTitle>Change Group Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Group Name"
            type="text"
            fullWidth
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseGroupNameDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitGroupName} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openInviteDialog} onClose={handleCloseInviteDialog}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInviteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitInvite} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PlayPage;
